import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ICurrency } from '@bs/models/common/currency';

/*
 * Format a value to the locale currency and money format
 * Usage:
 *   integer | moneyFormat
 * Example:
 *   {{ 12300 | moneyFormat }}
 *   formats to: &euro;123.00
*/

/**
 * Format a value to the locale currency and money format, output: 'formats to: &euro;123.00'
 */
@Pipe({
  name: 'moneyFormat',
  standalone: true
})
export class MoneyFormatPipe implements PipeTransform {

  /**
   * The constructor
   */
  constructor(private currencyPipe: CurrencyPipe) {
  }

  /**
   * returns us the money format string
   * @param value {number} the amount to be formatted as currency.
   * @param currency {Partial<ICurrency>} currency code, such as USD for the US dollar and EUR for the euro.
   * @param fromCents {boolean} the format for the currency indicator. it can be: 'code' or ''
   * @param displayCurrency {boolean} decimal representation options, specified by a string
   * @param showCents {boolean} how many decimals to be visible
   *
   */
  transform(value: number, currency: Partial<ICurrency>, fromCents = true, displayCurrency = true, showCents = true): string {

    if (!value) {
      value = 0;
    }

    const digitInfo = showCents ? '1.2-2' : '1.0-2';

    value = fromCents ? (value / 100) : value;

    if (displayCurrency) {
      return this.currencyPipe.transform(value, currency.symbol, 'code', digitInfo);
    } else {
      return this.currencyPipe.transform(value, currency.symbol, '', digitInfo);

    }
  }
}
